<template>
<div class="d-flex">
  <ul id="main-menu-navigation" class="nav navbar-nav">
    <li 
      class="nav-item b-nav-dropdown dropdown dropdown-user"
      :class="{
        'sidebar-group-active active open': isActiveMenuUser,
        'show': isOpenMenuUser,
      }"
      @mouseenter="() => updateGroupOpenMenuUser(true)"
      @mouseleave="() => updateGroupOpenMenuUser(false)"
    >
      <b-link class="nav-link dropdown-user d-flex align-items-center dropdown-user-link">
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name">
            {{ userData ? userData.firstName + ' ' + userData.lastName : "" }}
          </p>
          <span class="user-status">{{ userData.roles && userData.roles.toString()!=="" ? userData.roles.toString() : "Utilisateur" }}</span>
        </div>
        <b-avatar
          size="32"
          :src="userData? userData.avatar : ''"
          class="rounded bg-white text-primary"
        >
          <span class="b-avatar-text">
            <div class="text-primary">{{userData ? userData.firstName.charAt(0) + userData.lastName.charAt(0)  : 'ND'}}</div>
          </span>
        </b-avatar>
      </b-link>
      <ul class="dropdown-menu">
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push('/account')"
        >
          <span>
            <feather-icon
              size="16"
              icon="UserIcon"
              class="mr-50"
            />
            Mon Compte
          </span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <span>
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
            />
            Déconnexion
          </span>
        </b-dropdown-item>
      </ul>
    </li>
    <li 
      class="nav-item b-nav-dropdown dropdown dropdown-user"
      :class="{
        'sidebar-group-active active open': isActive,
        'show': isOpen,
      }"
      @mouseenter="() => updateGroupOpen(true)"
      @mouseleave="() => updateGroupOpen(false)"
    >
      <b-link class="nav-link dropdown-user d-flex align-items-center dropdown-user-link">
        <div class="d-sm-flex d-none user-nav mr-0">
          <span class="user-status" style="margin-top:0.7rem">Espace de travail</span>
          <p class="user-name">
            {{workspaceSelected.socialReason}} <span style="font-size:0.75rem">▼</span>
          </p>
        </div>
      </b-link>
      <ul class="dropdown-menu">
        <vs-divider  v-if="verifAdmin" color="#cccccc" class="m-0 mt-1 mb-50 text-primary" position="left">
          <span class="text-primary">Entreprise</span>
        </vs-divider>
        <li v-if="verifAdmin">
          <b-link
            :to="{ name: 'users'}"
            class="dropdown-item"
          >
            <span class="menu-title">
              <feather-icon
                icon="KeyIcon"
                class="m-0 mr-50"
                size="16"
              />
              Utilisateurs
            </span>
          </b-link>
        </li>
        <vs-divider color="#cccccc" class="m-0 mt-1 mb-50 text-primary" position="left" v-if="workspacesListByUser.length>1">
          <span class="text-primary">Changer d'entreprise</span>
        </vs-divider>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          v-for="(workspace, index) of workspacesListByUser"
          v-show="workspacesListByUser.length>1 && workspace.id !==workspaceSelected.id"
          :key="index"
          @click="onSwitchWorkspace(workspace.id, workspace.socialReason)"
        >
          <span class="d-flex justify-content-between w-100">
            {{workspace.socialReason}}
            <feather-icon
              size="16"
              icon="ArrowRightCircleIcon"
              class="mr-0"
            />
          </span>
        </b-dropdown-item>

      </ul>
    </li>
  </ul>
   <vue-element-loading background-color="rgba(24, 54, 109, .9)" color="#f1f1f1" :active="loadingSwitchWorkspace" :is-full-screen="true" :text="'Chargement des données de l\'entreprise '+workspaceName"/>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import VueElementLoading from 'vue-element-loading'
import useHorizontalNavMenuHeaderGroup from '@/@core/layouts/layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-header-group/useHorizontalNavMenuHeaderGroup'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    VueElementLoading
  },
  setup(props) {

    let userData = JSON.parse(localStorage.getItem('login'))?.login?.userInfo
    let workspaceName = ""
    let loadingSwitchWorkspace = false
    const {
      isActive,
      updateIsActive,
      isOpen,
      updateGroupOpen,
      isActiveMenuUser,
      updateIsActiveMenuUser,
      isOpenMenuUser,
      updateGroupOpenMenuUser,

    } = useHorizontalNavMenuHeaderGroup(props.item)

    return {
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
      isOpenMenuUser,
      isActiveMenuUser,
      updateGroupOpenMenuUser,
      updateIsActiveMenuUser,
      userData,
      workspaceName,
      loadingSwitchWorkspace
    }
  },
  // data() {
  //   return {
  //   }
  // }, 
  methods: {
    ...mapActions(['fetchWorkspacesListByUser']),
    verifAdmin(){
      if(!this.userData.roles.includes('Admin')){
        return false
      }else{
        return true
      }
    },
    onSwitchWorkspace(id, workspaceName) {
      let _this = this
      this.$bvModal
        .msgBoxConfirm('Vos onglets seront ré-actualisés avec les données de la nouvelle entreprise choisie. Seul les formulaires d\'édition seront fermés.', {
          title:
            'Souhaitez-vous actualiser ou quitter les onglets en cours ?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Actualiser',
          cancelTitle: 'Quitter',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$tabs.items.filter(elem=>{
              if(elem.closeOnSwitchWorkspace){
                _this.$tabs.close(elem.id,null)
              }
            })
          }else{
            this.$tabs.reset('/')
          }

          this.workspaceName=workspaceName;
          this.loadingSwitchWorkspace=true
          setTimeout(() => {
            this.loadingSwitchWorkspace=false
          }, 2000);
          this.$store.dispatch('setWorkspaceSelected', { workspaceSelectedId:id, userRoles:this.userData.roles});
          this.$nextTick(() => {
            this.$tabs.refreshAll()
          })
        })

    },
    logout() { 
      this.$store.dispatch('logOut')
      this.$router.push('/login')
    },
  },
  computed: {
   ...mapGetters(['workspacesListByUser', 'isLoadingworkspacesListByUser', 'userInfo', 'workspaceSelected']),
  },
  created () {
    this.$store.dispatch('fetchWorkspacesListByUser')
  }
}
</script>
